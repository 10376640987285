import { EsetSharedModule } from '@eset/eset-shared';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { reducers, metaReducers } from './reducers';
import { environment } from '@environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { IonicModule } from '@ionic/angular';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadSessionEffects } from '@shared/effects/load-session.effects';
import { EndSessionEffects } from '@shared/effects/end-session.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [AppComponent],
  imports: [
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    EffectsModule.forRoot([LoadSessionEffects, EndSessionEffects]),
    SharedModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
    EsetSharedModule,
    NgxCsvParserModule,
    NgxMaskPipe,
    NgxMaskDirective,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    NgxPayPalModule,
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: environment.currency_symbol,
    },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
