import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { HeaderComponent } from './components/header/header.component';
import { GravatarModule } from 'ngx-gravatar';
import { IonicModule } from '@ionic/angular';
import { PopoverListComponent } from './components/popover-list/popover-list.component';
import { RouterModule } from '@angular/router';
import { NavTabHeaderComponent } from './components/nav-tab-header/nav-tab-header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TooltipModule, TooltipOptions } from '@cloudfactorydk/ng2-tooltip-directive';
import { PaginationOptionsComponent } from './components/pagination-options/pagination-options.component';
import { AdvancedSearchComponent } from './components/advanced-search/advanced-search.component';
import { GroupSelectorComponent } from './components/group-selector/group-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupItemComponent } from './components/group-selector/group-item/group-item.component';
import { LoadGroupsEffects } from '@shared/effects/load-groups.effects';
import { StoreModule } from '@ngrx/store';
import * as fromUsers from '@features/users/reducers/users.reducer';
import * as fromCustomers from '@features/customers/admin/reducers/customers.reducer';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { MomentModule } from 'ngx-moment';
import { CurrencyPickerComponent } from './components/currency-picker/currency-picker.component';
import { ResendEmailConfirmationEffects } from '@features/users/effects/resend-email-confirmation.effects';
import { EmailPdfComponent } from './components/email-pdf/email-pdf.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { LoadGroupEffects } from '@shared/effects/load-group.effects';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { GroupDisplayComponent } from './components/group-display/group-display.component';
import { updateTypePipe } from './filters/quote-update-type.pipe';
import { QuotePurchaseTypePipe } from './filters/quote-purchase-type.pipe';
import { ObfuscateValueComponent } from './components/obfuscate-value/obfuscate-value.component';
import { SetCartConfigEffects } from '@features/cart/effects/set-cart-config.effects';
import { GroupSelectorModalComponent } from './components/group-selector-modal/group-selector-modal.component';
import { DiscountValueTypeComponent } from './components/discount-value-type/discount-value-type.component';
import { OrderSourcePipe } from './filters/order-source.pipe';
import { CreateCartEffects } from '@features/cart/effects/create-cart.effects';
import { SelectAddCustomerEffects } from '@features/customers/admin/effects/select-add-customer.effects';
import { UpdateCustomerEffects } from '@features/customers/admin/effects/update-customer.effects';
import { UserDisplayComponent } from './components/user-display/user-display.component';
import { EmojiFlagPipe } from './filters/emoji-flag.pipe';
import { CustomerTypeSelectorComponent } from '@shared/components/customer-type-selector/customer-type-selector.component';
import { ObserveElementDirective } from './directives/observe-element.directive';
import { EmailInputComponent } from './components/email-input/email-input.component';

@NgModule({
  declarations: [
    SideNavComponent,
    HeaderComponent,
    PopoverListComponent,
    NavTabHeaderComponent,
    LoaderComponent,
    EmptyStateComponent,
    PaginationOptionsComponent,
    AdvancedSearchComponent,
    GroupSelectorComponent,
    GroupItemComponent,
    GroupSelectorModalComponent,
    DatePickerComponent,
    CurrencyPickerComponent,
    EmailPdfComponent,
    EmptyStateComponent,
    MultiSelectComponent,
    updateTypePipe,
    GroupDisplayComponent,
    QuotePurchaseTypePipe,
    ObfuscateValueComponent,
    DiscountValueTypeComponent,
    OrderSourcePipe,
    UserDisplayComponent,
    CustomerTypeSelectorComponent,
    ObserveElementDirective,
    EmailInputComponent,
  ],
  imports: [
    CommonModule,
    GravatarModule,
    IonicModule,
    RouterModule,
    TooltipModule.forRoot({
      'tooltip-class': 'default-tooltip',
      'hide-delay': 0,
      'z-index': 99999,
    } as TooltipOptions),
    ReactiveFormsModule,
    MomentModule,
    StoreModule.forFeature(fromUsers.featureKey, fromUsers.reducer),
    StoreModule.forFeature(fromCustomers.featureKey, fromCustomers.reducer),
    EffectsModule.forFeature([
      LoadGroupsEffects,
      LoadGroupEffects,
      ResendEmailConfirmationEffects,
      SetCartConfigEffects,
      CreateCartEffects,
      SelectAddCustomerEffects,
      UpdateCustomerEffects,
    ]),
    FormsModule,
    EmojiFlagPipe,
  ],
  exports: [
    TooltipModule,
    SideNavComponent,
    HeaderComponent,
    PopoverListComponent,
    NavTabHeaderComponent,
    LoaderComponent,
    EmptyStateComponent,
    PaginationOptionsComponent,
    AdvancedSearchComponent,
    GroupSelectorComponent,
    DatePickerComponent,
    CurrencyPickerComponent,
    GroupDisplayComponent,
    updateTypePipe,
    QuotePurchaseTypePipe,
    ObfuscateValueComponent,
    DiscountValueTypeComponent,
    OrderSourcePipe,
    UserDisplayComponent,
    CustomerTypeSelectorComponent,
    ObserveElementDirective,
    EmailInputComponent,
  ],
})
export class SharedModule {}
